$(function(){
    $('#mv').slick({
        autoplay: true,
        dots: false,
        arrows: false,
        fade: true,
        slidesToShow: 1,
        pauseOnFocus: false,
        pauseOnHover: false,
        autoplaySpeed: 3000,
        speed: 3000
    });
});